import React from 'react'

const Cursor = () => {
  // const cursor = document.querySelector(".cursor");
  // const cursor2 = document.querySelector(".cursor2");
  // document.addEventListener("mousemove", (e) => {
  //   cursor.style.cssText = cursor2.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px;";
  // });

  return (
    <>
    <div className='cursor'>
      <div className='cursor2'>

      </div>
    </div>
    </>
  )
}

export default Cursor
